import DownloadLink from './DownloadLink';
import DownloadText from './DownloadText';
import DownloadIcon from './DownloadIcon';

class StickyButtonSwitcher {
    constructor() {
        new DownloadText('.StickyButton.StickyButton--personal .StickyButton__label', 'PCにリンクを送る');
        new DownloadLink('.StickyButton.StickyButton--personal', 'https://toruno.biz/');
        new DownloadIcon('.StickyButton.StickyButton--personal .Icon--download','Icon--download', 'Icon--share');
    }
}

export default StickyButtonSwitcher;