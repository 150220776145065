/* global $ */

class SmoothScroll {
    constructor(Adjust,Speed) {
        $('a[href^="#"]').click(function () {
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top + Adjust;
            $('body,html').animate({ scrollTop: position }, Speed, 'swing');
            return false;
        });
    }
}

export default SmoothScroll;