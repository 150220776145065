/* global $ */

class Accordion {
    constructor() {
        const ClassName = 'FaqAccordion';
        const ClassNameOpen = ClassName + '--open';
        const SelectorName = $("." + ClassName);
        const ButtonName = $(".FaqAccordion__heading");

        ButtonName.click(function () {
            $(this).parent(SelectorName).toggleClass(ClassNameOpen);
            $(this).next('.FaqAccordion__body').slideToggle(300);
        });
    }
}

export default Accordion;