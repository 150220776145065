import DownloadLink from './DownloadLink';
import DownloadText from './DownloadText';
import DownloadIcon from './DownloadIcon';

class RectButtonSwitcher {
    constructor() {
        new DownloadText('.js-Download .ButtonLabel', 'PCにリンクを送る');
        new DownloadLink('.js-Download', 'https://toruno.biz/');
        new DownloadIcon('.js-Download .Icon--download','Icon--download', 'Icon--share');
    }
}

export default RectButtonSwitcher;