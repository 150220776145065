/* global $ */

class ScrollTrigger {
    constructor(ClassName) {
        const classname = ClassName;
        const AfterClassName = classname.replace('.js', 'is');

        const FadeUpTrigger = $(classname);

        FadeUpTrigger.each(function () {
            const elemPos = $(this).offset().top;
            const scroll = $(window).scrollTop();
            const windowHeight = $(window).height();
            if (scroll >= elemPos - windowHeight*4/5) {
                $(this).addClass(AfterClassName);
            }
        });
    }
}


export default ScrollTrigger;