/* global $ */

class Header {
    constructor() {
        const RicohHeader = $('.header-ricoh');
        const Header = $('.Header');
        const Footer = $('.Footer');
        const PageMv = $('.PageMv');
        const RicohHeaderHeight = RicohHeader.height();
        const FooterOffset = Footer.offset().top + Footer.outerHeight() * 5 / 7;


        $(window).scroll(function () {
            if ($(this).scrollTop() >= RicohHeaderHeight) {
                Header.addClass('Header--fixed');
            }
            else {
                Header.removeClass('Header--fixed');
            }


            if ($('section').hasClass('PageMv--common')) {
                const PaveMvOffset = PageMv.offset().top;

                if ($(this).scrollTop() >= PaveMvOffset) {
                    Header.removeClass('Header--white');

                } else {
                    Header.addClass('Header--white');
                }

            }


            if ($(this).scrollTop() + $(window).height() >= FooterOffset) {
                Header.addClass('Header--hide');
            } else {
                Header.removeClass('Header--hide');
            }
        });
    }
}

export default Header;