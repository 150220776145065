/* global $ */

class HamburgerMenu {
    constructor() {
        this.$window = $(window);
        this.mediaQuery = window.matchMedia('(min-width: 786px)')

        this.isOpen = false;
        this.$btn = $(".SpMenuButton");

        this.$btn.click(() => {
            if(this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        });

        this.$window.on('resize', () => {
            this.onWindowResize();
        });
    }

    open() {
        this.isOpen = true;
        this.$btn.addClass("SpMenuButton--open");
        $(".SpMenu").addClass("SpMenu--open");
        $(".Header").addClass("Header--open");
    }

    close() {
        this.isOpen = false;
        this.$btn.removeClass("SpMenuButton--open");
        $(".SpMenu").removeClass("SpMenu--open");
        $(".Header").removeClass("Header--open");
    }

    onWindowResize() {
        if(this.mediaQuery.matches) {
            if(this.isOpen) {
                this.close();
            }
        }
    }
}

export default HamburgerMenu;
